import { useEffect, useRef, useState } from 'react';
import { Button, Flex, Select, Spin, Typography } from 'antd';
import { LinkedInManager, OrganizationMemberManager } from '@copilot/data';
import { useEffectAsync, useFetch } from '@copilot/common/hooks/common';
import { LinkedInMetaActions } from '@copilot/common/store/actions/linkedInMeta';
import LinkedinLoginResult, {
	LinkedInResultStatus,
} from '@copilot/common/components/linkedin/login/result';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Features } from '@copilot/data/responses/interface';
import { HttpStatusCode } from '@copilot/common/utils/httpStatusCode';
import isNil from 'lodash/isNil';
import { RTCState } from './types';
import { streamLoginHelper } from './helperMethods';
import notificationManager from '@copilot/common/utils/notificationManager';
import { IProxy } from '@copilot/common/store/models/redux';
import { LoadingOutlined } from '@ant-design/icons';
import { useRegionalProxies } from '../regions/useRegionalProxies';
import { SignalRPeer } from '@copilot/common/screensharing/client';
import { useGenerateLinkedInAuthMutation } from '@copilot/data/graphql/_generated';

type LinkedInSettingsProps = {
	member: { id: string; vpnPort?: number };
	isLoggedIn: boolean;
	isRetrying: boolean;
	onProxySet: (newProxy: IProxy) => Promise<void>;
};

export default function LinkedInLogIn({
	member,
	isLoggedIn,
	onProxySet,
	isRetrying,
}: LinkedInSettingsProps) {
	const isLoginEnabled = useFeatureToggle(Features.LinkedInLogoutIncidentFeature);

	const isHostedAuthEnabled = useFeatureToggle(Features.UseHostedAuthFeature);
	const [getAuthUrl] = useGenerateLinkedInAuthMutation();

	const { hasProxySet, regionOptions, selectProxyFromRegion } = useRegionalProxies({
		userVpnPort: member.vpnPort,
	});
	const [newRegion, setNewRegion] = useState<string | undefined>(undefined);
	const [isLoggedInFetch, fetchIsLoggedIn] = useFetch(
		(id: string) =>
			OrganizationMemberManager.checkIsLoggedIn(id).then((r) => ({
				orgMemberId: member.id,
				isLoggedIn: r,
			})),
		LinkedInMetaActions.loadLinkedInMeta
	);

	const rtcRef = useRef<SignalRPeer>();

	useEffectAsync(async () => {
		await fetchIsLoggedIn(member.id);
	}, [member.id]);

	const partnerVideoRef = useRef<HTMLVideoElement>(null);
	const [loginRoomName, fetchLoginRoomName] = useFetch(LinkedInManager.getLoginRoomName);
	const [rtcState, setRtcState] = useState<RTCState>('preparing');
	const [isRtcLoginVisible, setIsRtcLoginVisible] = useState<boolean>(false);
	const [isSettingRegion, setIsSettingRegion] = useState<boolean>(false);
	const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);

	useEffect(() => {
		return () => {
			if (rtcRef.current) {
				rtcRef.current.close();
				rtcRef.current = undefined;
			}
		};
	}, []);

	async function showLoginRoom() {
		if (isHostedAuthEnabled) {
			try {
				//TODO https://cassia.atlassian.net/browse/LIR-341 pass in url params when available
				const res = await getAuthUrl({
					variables: {
						input: {
							orgMemberId: member.id,
							successRedirectUrl: window.location.href,
							failureRedirectUrl: window.location.href,
						},
					},
				});
				const data = res.data?.generateLinkedInAuth;
				if (data?.__typename === 'LinkedInAuthDTO') {
					window.open(data.url, '_blank');
				} else {
					console.error('Non-string url returned');
				}
			} catch (e) {
				console.error("Couldn't generate LinkedIn auth URL", e);
			}
		} else {
			setIsRtcLoginVisible(true);
			setIsLoaderVisible(true);
			const roomName = await fetchLoginRoomName(member.id);
			if (!isNil(rtcRef.current)) {
				rtcRef.current.close();
				rtcRef.current = undefined;
			}
			streamLoginHelper({
				roomName,
				rtcState,
				setRtcState,
				partnerVideoRef,
				setIsLoaderVisible,
				rtcRef,
			});
		}
	}

	const hasTooManyRequest =
		!loginRoomName.isFetching && loginRoomName.error?.status === HttpStatusCode.TooManyRequests;

	async function onSetRegion() {
		const selectedProxy = selectProxyFromRegion(newRegion);
		if (isNil(selectedProxy)) {
			return;
		}

		setIsSettingRegion(true);
		try {
			await onProxySet(selectedProxy);
			notificationManager.showSuccessNotification({
				message: 'Region set successfully!',
			});
		} catch (e) {
			notificationManager.showErrorNotification({
				message: 'There was an issue setting your Region',
				description: 'Please try again or contact support if the error persists.',
			});
		}
		setIsSettingRegion(false);
	}

	if (!hasProxySet) {
		return (
			<div>
				<LinkedinLoginResult
					header={
						'Please select the region nearest to your usual location before connecting to LinkedIn'
					}
					status={LinkedInResultStatus.Error}
				/>
				<Flex gap="small" justify="center">
					<Select
						style={{ minWidth: '200px' }}
						placeholder="Select a region..."
						onChange={setNewRegion}
						disabled={isSettingRegion}
					>
						{regionOptions.map((region) => (
							<Select.Option key={region} value={region}>
								{region}
							</Select.Option>
						))}
					</Select>
					<Button
						disabled={isNil(newRegion)}
						onClick={() => void onSetRegion()}
						loading={isSettingRegion}
					>
						Set Region
					</Button>
				</Flex>
			</div>
		);
	}

	return (
		<div>
			{!isLoginEnabled ? (
				<div>
					<LinkedinLoginResult
						callToAction="Please check back later to reconnect your LinkedIn account."
						header="This feature is currently under maintenance"
					/>
				</div>
			) : (
				<div>
					{!isLoggedInFetch.isFetching && (
						<>
							{!isRtcLoginVisible && (
								<div>
									{isLoggedIn ? (
										<LinkedinLoginResult
											callToAction="Service running fine. No actions needed."
											header="Your LinkedIn is successfully connected"
											status={LinkedInResultStatus.Success}
											onClickCallback={() => void showLoginRoom()}
											buttonText="Reconnect"
										/>
									) : (
										<>
											{isRetrying ? (
												<LinkedinLoginResult
													header="Your linkedin account is currently syncing."
													callToAction="Automation will resume once the sync is complete."
													status={LinkedInResultStatus.Syncing}
												/>
											) : (
												<LinkedinLoginResult
													callToAction="You will need to connect a LinkedIn account to use the platform."
													header="Please reconnect your LinkedIn account"
													status={LinkedInResultStatus.Error}
													onClickCallback={() => void showLoginRoom()}
													buttonText="Reconnect"
												/>
											)}
										</>
									)}
								</div>
							)}

							{isRtcLoginVisible && (
								<>
									{isLoaderVisible && !loginRoomName.error && (
										<div style={{ textAlign: 'center', height: '100%' }}>
											<Spin size={'large'} indicator={<LoadingOutlined />} />
											<Typography.Title level={3}>
												Loading. Please wait...
											</Typography.Title>
										</div>
									)}
									{rtcState === 'preparing' &&
										!loginRoomName.isFetching &&
										loginRoomName.error && (
											<div style={{ textAlign: 'center', height: '100%' }}>
												{hasTooManyRequest ? (
													<LinkedinLoginResult
														header="Too many users are currently logging in. Please try again later"
														status={LinkedInResultStatus.Error}
														buttonText="Try again"
														onClickCallback={() => void showLoginRoom()}
													/>
												) : (
													<LinkedinLoginResult
														header="Sorry an error has occurred. Please try again"
														status={LinkedInResultStatus.Error}
														buttonText="Try again"
														onClickCallback={() => void showLoginRoom()}
													/>
												)}
											</div>
										)}

									{rtcState === 'running' && (
										<div
											style={{
												overflow: 'hidden',
												alignItems: 'center',
												justifyContent: 'center',
												display: 'flex',
												opacity: !isLoaderVisible ? 1 : 0,
											}}
										>
											<video
												height={'100%'}
												playsInline
												autoPlay
												className="video"
												ref={partnerVideoRef}
											>
												Please wait while we connect you to LinkedIn
											</video>
										</div>
									)}

									{rtcState === 'basic_login_success' && (
										<div
											style={{
												textAlign: 'center',
												height: '100%',
												width: 410,
											}}
										>
											<Spin size={'large'} indicator={<LoadingOutlined />} />
											<Typography.Title level={3}>
												Syncing Your LinkedIn Account...
											</Typography.Title>
											<Typography.Text type={'secondary'}>
												Please hold on while we sync your account with our
												platform.
												<br />
												To ensure a successful connection, avoid navigating
												away until the process is complete.
											</Typography.Text>
										</div>
									)}

									{rtcState === 'complete' && (
										<div>
											<LinkedinLoginResult
												header="Your LinkedIn is successfully connected"
												status={LinkedInResultStatus.Success}
											/>
										</div>
									)}
									{rtcState === 'timeout' && (
										<div>
											<LinkedinLoginResult
												header="We've exceeded our time limit, please refresh and try again"
												status={LinkedInResultStatus.Error}
											/>
										</div>
									)}
									{rtcState === 'account_restricted' && (
										<div>
											<LinkedinLoginResult
												header="Your LinkedIn account is currently restricted"
												callToAction="Please wait for your restriction to lift before trying to connect to our platform."
												status={LinkedInResultStatus.Error}
											/>
										</div>
									)}
									{rtcState === 'missing_salesnav' && (
										<div>
											<LinkedinLoginResult
												header="Your LinkedIn account is missing Sales Navigator"
												callToAction="Please ensure you have an active Sales Navigator subscription and refresh the page to try again."
												status={LinkedInResultStatus.Error}
											/>
										</div>
									)}
									{rtcState === 'sales_nav_onboarding_incomplete' && (
										<div>
											<LinkedinLoginResult
												header="Finishing activating Sales Navigator"
												callToAction="To successfully sync your account to CoPilot AI, you need to activate your Sales Navigator subscription. Please log in and try again."
												status={LinkedInResultStatus.Error}
											/>
										</div>
									)}
									{/* TODO LIR-152 */}
									{rtcState === 'unipile_login_failed' && (
										<LinkedinLoginResult
											header="Sorry an error has occurred."
											callToAction="Our engineers are on it. Please try again later."
											status={LinkedInResultStatus.Error}
										/>
									)}
								</>
							)}
						</>
					)}
					{isLoggedInFetch.isFetching && (
						<div style={{ textAlign: 'center' }}>
							<Spin size={'large'} indicator={<LoadingOutlined />} />
						</div>
					)}
				</div>
			)}
		</div>
	);
}
